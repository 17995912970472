export enum StoreType {
    Any = 0,
    DC = 1,
    Store = 2,
    
}

export enum DepartmentType{
    Any = 0,
    FreshFood = 2,
    DryFood = 1
}

export enum LogoutType{
    Self = 1,
    Admin = 2
}

export enum ForecastAdjustmentTypeEnum {
        ByPecentage = 2,
        ByValue = 1
}

export enum ForecastType{
    Daily = 1,
    Weekly = 2
}

export enum OrderMethodType {
    PBS = 1,
    PBL = 2,
    Direct = 3
}

export enum ScheduleTypes {
            PullSales = 1, 
            PrepareSales = 2, 
            CalculateBaselineForecast = 3,
            PullItemHirarchy = 4,
            PullItems = 5,
            PullStoreDc = 6,
            PullSuppliers = 7,
            PullStoreItems = 8,
            PullGapScan = 9,
            GenerateDailyForecast = 10,
            PushDryStoreOrder = 15,
            PushFreshStoreOrder = 16,
            PushDryDCOrder = 17,
            PushFreshDCOrder = 18,
            PullLostSale = 11,
            PullSupllierItemFromSap=30,
            PullStockTakeFromSap=31
}

export enum ProcessStatus {
            Running=1,Completed=2,Failed=3,
}

export enum ProcessAction {
            RunAuto = 1, RunManually = 2, ReStartedAuto = 3, ReStartedManually=4
        }

export enum ProcessType {
    PullData=1,PushData=2,InternalProcess=3,
}

export enum DataSourceType {
    Database = 1, RestAPI = 2,
}