import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { ManualOrderAmend } from '../models/maintenance/Order';

@Injectable({
    providedIn: 'root',
})
export class SharedService {
    //screenFunction : any ;

  selectedStoreList  = [];
  selectedItemList  = [];
  selectedDepartmentList=[];
  selectedStoreClusterList  = [];
  paginationFilterRanges = [
    {id:1,value:'0-10'},
    {id:2,value:'0-50'},
    {id:3,value:'0-100'},
    {id:4,value:'100+'}
  ]

 public ServiceURL =  "http://localhost:17591/";
 //public ServiceURL =  "https://jmsl-oms-dev-backend.azurewebsites.net/";
 

  private selectedItemSource = new BehaviorSubject<any>({
    ItemName:'THAI HERITAGE PASTE CORIANDER 100ML',
    ItemID:1
  });
  

    screenFunction = JSON.parse(localStorage.getItem('screenFunction'));
    permission: any = [];
    url = window.location.pathname.slice(7);


    if(screenFunction) {
        console.log(this.url);
        console.log(screenFunction);
        var url = window.location.pathname.slice(7);
        var func = screenFunction.filter(x => x.url == url);
        console.log("func");
        console.log(func);
        console.log(url);
        this.permission = func;
        return screenFunction;

    }

    private selectedSearchParams = new BehaviorSubject<any>(1);
    private selectedSearchParamsBufferDetails = new BehaviorSubject<any>(1);
    private selectedSearchParamsWasteDetails = new BehaviorSubject<any>(1);
    private selectedSearchParamsWasteItemDetails = new BehaviorSubject<any>(1);
    private selectedStoreID = new BehaviorSubject<any>(1);
    private selectedDepartment = new BehaviorSubject<any>(1);
    private selectedSubDepartment = new BehaviorSubject<any>(1);
    private selectedCategory = new BehaviorSubject<any>(1);
    private selectedSubCategory = new BehaviorSubject<any>(1);
    private SelectedMecrchandiseCategory = new BehaviorSubject<any>(1);
    private selectedStore = new BehaviorSubject<any>(1);
    private selectedStoreCluster = new BehaviorSubject<any>(1);
    private selectedCategories = new BehaviorSubject<any>(1);
    private selectedTobeDeliveredDate = new BehaviorSubject<any>(1);
    private selectedCriteria = new BehaviorSubject<any>(1);
    private selectedIsProvisionDetailScrn = new BehaviorSubject<any>(1);
    private selectedIsOrderDetailsScrn = new BehaviorSubject<any>(1);
    private selectedBufferItem = new BehaviorSubject<any>(1);
    private selectedFromDate = new BehaviorSubject<any>(1);
    private selectedToDate = new BehaviorSubject<any>(1);
    private selectedBufferOrderGroup = new BehaviorSubject<any>(1);
    private selectedIsOrderScrn = new BehaviorSubject<any>(1);

    private selectedStoreManualOrder = new BehaviorSubject<any>(1);
    private selectedSupplierManualOrder = new BehaviorSubject<number[]>([]);//new BehaviorSubject<any>(1);
    private selectedDeliveryDateManualOrder = new BehaviorSubject<any>(1);
    private selectedOrderNumberManualOrder = new BehaviorSubject<any>(1);
    private selectedUrlManualOrder = new BehaviorSubject<any>(1);

    private selectedStoreCustomerOrder = new BehaviorSubject<any>(1);
    private selectedDeliveryDateCustomerOrder = new BehaviorSubject<any>(1);
    private selectedOrderNumberCustomerOrder = new BehaviorSubject<any>(1);
    private selectedUrlCustomerOrder = new BehaviorSubject<any>(1);

    private selectedStoreBOMOrder = new BehaviorSubject<any>(1);
    private selectedDeliveryDateBOMOrder = new BehaviorSubject<any>(1);
    private selectedOrderNumberBOMOrder = new BehaviorSubject<any>(1);
    private selectedUrlBOMOrder = new BehaviorSubject<any>(1);
    private isItemSelectionCriteriaEnable= new BehaviorSubject<any>(1);
    private StoreType = new BehaviorSubject<number>(2);
    private StoreTypeForAllocation = new BehaviorSubject<number>(2);



    setSelectedStores(items) {
        this.selectedStoreList = items;
    }
    setSelectedItems(items) {
        this.selectedItemList = items;
    }

    setSelectedDepartment(items) {
        this.selectedDepartmentList = items;
    }
    setSelectedStoreCluster(items) {
        this.selectedStoreClusterList = items;
    }


    //currentPermission = this.permission.asObservable();  
    currentSelectedItem = this.selectedItemSource.asObservable();
    currentSelectedStoreCluster = this.selectedStoreCluster.asObservable();
    currentSelectedDepartment = this.selectedDepartment.asObservable();
    currentSelectedSubDepartment = this.selectedSubDepartment.asObservable();
    currentSelectedCategory = this.selectedCategories.asObservable();
    currentSelectedSubCategory = this.selectedSubCategory.asObservable();
    currentSelectedMerchandizeCategory = this.SelectedMecrchandiseCategory.asObservable();
    currentStoreList = this.selectedStore.asObservable();
    currentSearchParams = this.selectedSearchParams.asObservable();
    currentSearchParamsBufferDetails = this.selectedSearchParamsBufferDetails.asObservable();
    currentSearchParamsWasteDetails = this.selectedSearchParamsWasteDetails.asObservable();
    currentSearchParamsWasteItemDetails = this.selectedSearchParamsWasteItemDetails.asObservable();
    currentStoreID = this.selectedStoreID.asObservable();
    private DepartmentType = new BehaviorSubject<number>(1);
    currentSelectedDepartmentType = this.DepartmentType.asObservable();
    currentTobeDeliveredDate = this.selectedTobeDeliveredDate.asObservable();
    curretSelectedCriteria = this.selectedCriteria.asObservable();
    currentSelectedIsProvisionDetailScrn = this.selectedIsProvisionDetailScrn.asObservable();
    currentSelectedIsOrderDetailsScrn = this.selectedIsOrderDetailsScrn.asObservable();
    currentBufferItem = this.selectedBufferItem.asObservable();
    currentBufferFromDate = this.selectedFromDate.asObservable();
    currentBufferToDate = this.selectedToDate.asObservable();
    currentBufferOrderGroup = this.selectedBufferOrderGroup.asObservable();
    currentSelectedIsOrderScrn = this.selectedIsOrderScrn.asObservable();

    currentSelectedStoreManualOrder = this.selectedStoreManualOrder.asObservable();
    currentSelectedSupplierManualOrder = this.selectedSupplierManualOrder.asObservable();
    currentSelectedDeliveryDateManualOrder = this.selectedDeliveryDateManualOrder.asObservable();
    currentSelectedOrderNumberManualOrder = this.selectedOrderNumberManualOrder.asObservable();
    currentSelectedUrlManualOrder = this.selectedUrlManualOrder.asObservable();

    currentSelectedStoreCustomerOrder = this.selectedStoreCustomerOrder.asObservable();
    currentSelectedDeliveryDateCustomerOrder = this.selectedDeliveryDateCustomerOrder.asObservable();
    currentSelectedOrderNumberCustomerOrder = this.selectedOrderNumberCustomerOrder.asObservable();
    currentSelectedUrlCustomerOrder = this.selectedUrlCustomerOrder.asObservable();

    currentSelectedStoreBOMOrder = this.selectedStoreBOMOrder.asObservable();
    currentSelectedDeliveryDateBOMOrder = this.selectedDeliveryDateBOMOrder.asObservable();
    currentSelectedOrderNumberBOMOrder = this.selectedOrderNumberBOMOrder.asObservable();
    currentSelectedUrlBOMOrder = this.selectedUrlBOMOrder.asObservable();
    currentItemSelectionCriteria=this.isItemSelectionCriteriaEnable.asObservable();
     
    currentStoreType = this.StoreType.asObservable();
    currentStoreTypeForAllocation=this.StoreTypeForAllocation.asObservable();

    constructor() { }

    changeSelectedItem(item: any) {
        this.selectedItemSource.next(item);
    }


    changeSelectedDepartment(item: any) {
        this.selectedDepartment.next(item);
    }


    changeSelectedSubDepartment(item: any) {
        this.selectedSubDepartment.next(item);
    }

    changeSelectedStore(item: any) {
        this.selectedStore.next(item);
    }

    changeSelectedStoreCluster(item: any) {
        this.selectedStoreCluster.next(item);
    }


    changeSelectedSubCategory(item: any) {
        this.selectedSubCategory.next(item);
    }

    changeSelectedMecrchandiseCategory(item: any) {
        this.SelectedMecrchandiseCategory.next(item);
    }


    changeSelectedDepartmentType(type: number) {
        this.DepartmentType.next(type);
    }

    changeSelecteStoreType(type: number) {
        this.StoreType.next(type);
    }


    changeSelecteStoreTypeForAllcotion(type: number) {
        this.StoreTypeForAllocation.next(type);
    }




    changeSelectedSearchParamas(params: any) {
        this.selectedSearchParams.next(params);
    }
    searchParamasBufferStockSummaryDetails(params: any) {
        this.selectedSearchParamsBufferDetails.next(params);
    }
    searchParamasWasteDetails(params: any) {
        this.selectedSearchParamsWasteDetails.next(params);
    }

    searchParamasWasteItemDetails(params: any) {
        this.selectedSearchParamsWasteItemDetails.next(params);
    }

    changeStoreID(params: any) {
        this.selectedStoreID.next(params);
    }

    currentCategories = this.selectedCategories.asObservable();

    changeSelectedCategory(item: any) {
        this.selectedCategories.next(item);
    }

    private selectedWHProductType = new BehaviorSubject<any>(1);
    currentWHProductType = this.selectedWHProductType.asObservable();
    changeSelectedWHProductType(item: any) {
        this.selectedWHProductType.next(item);
    }
    
    private selectedMerchandiseCategory = new BehaviorSubject<any>(1);
    currentMerchandiseCategory = this.selectedMerchandiseCategory.asObservable();
    changeSelectedMerchandiseCategory(item: any) {
        this.selectedMerchandiseCategory.next(item);
    }

    private selectedSubCategories = new BehaviorSubject<any>(1);
    currentSubCategories = this.selectedSubCategories.asObservable();
    changeSelectedSubCategories(item: any) {
        this.selectedSubCategories.next(item);
    }

    private selectedSubDeparments = new BehaviorSubject<any>(1);
    currentSubDeparments = this.selectedSubDeparments.asObservable();
    changeSelectedSubDepartments(item: any) {
        this.selectedSubDeparments.next(item);
    }
    private selectedDeparments = new BehaviorSubject<any>(1);
    currentDeparments = this.selectedDeparments.asObservable();
    changeSelectedDepartments(item: any) {
        this.selectedDeparments.next(item);
    }


    changeSelectedTobeDeliveredDate(item: any) {
        this.selectedTobeDeliveredDate.next(item);
    }

    changeSelectedCriteria(item: any) {
        this.selectedCriteria.next(item);
    }
    changeSelectedIsProvisionDetailScrn(item: boolean) {
        this.selectedIsProvisionDetailScrn.next(item);
    }
    changeSelectedIsOrderDetailsScrn(item: boolean) {
        this.selectedIsOrderDetailsScrn.next(item);
    }
    changeBufferItemList(params: any) {
        this.selectedBufferItem.next(params);
    }
    changeBufferFromDate(params: any) {
        this.selectedFromDate.next(params);
    }
    changeBufferToDate(params: any) {
        this.selectedToDate.next(params);
    }
    changeBufferOrderGroup(params: any) {
        this.selectedBufferOrderGroup.next(params);
    }
    changeSelectedIsOrdersScrn(item: boolean) {
        this.selectedIsOrderScrn.next(item);
    }
    changeManualOrderAmend(amend: ManualOrderAmend) {
        this.selectedStoreManualOrder.next(amend.storeID);
        this.selectedSupplierManualOrder.next(amend.supplierID);
        this.selectedDeliveryDateManualOrder.next(amend.deliveryDate);
        this.selectedOrderNumberManualOrder.next(amend.orderNumber);
        this.selectedUrlManualOrder.next(amend.url);
    }

    changeCustomerOrderAmend(amend: ManualOrderAmend) {
        this.selectedStoreCustomerOrder.next(amend.storeID);
        this.selectedDeliveryDateCustomerOrder.next(amend.deliveryDate);
        this.selectedOrderNumberCustomerOrder.next(amend.orderNumber);
        this.selectedUrlCustomerOrder.next(amend.url);
    }

    changeBomOrderAmend(amend: ManualOrderAmend) {
        this.selectedStoreBOMOrder.next(amend.storeID);
        this.selectedDeliveryDateBOMOrder.next(amend.deliveryDate);
        this.selectedOrderNumberBOMOrder.next(amend.orderNumber);
        this.selectedUrlBOMOrder.next(amend.url);
    }


    changeItemSelectionCriteria(isItemFilter: boolean) {
        this.isItemSelectionCriteriaEnable.next(isItemFilter);
      
    }



    private selectedFillRate7 = new BehaviorSubject<any>(1);
    currentFillRate7 = this.selectedFillRate7.asObservable();
    changeSelectedFillRate7(item: any) {
        this.selectedFillRate7.next(item);
    }

    private selectedFillRate28 = new BehaviorSubject<any>(1);
    currentFillRate28 = this.selectedFillRate28.asObservable();
    changeSelectedFillRate28(item: any) {
        this.selectedFillRate28.next(item);
    }

    private selectedSugoSummaryDCID = new BehaviorSubject<any>(1);
    private selectedSugoSummaryDate = new BehaviorSubject<any>(1);
    private selectedSugoSummaryUrl = new BehaviorSubject<any>(1);
    currentSugoSummaryDCID = this.selectedSugoSummaryDCID.asObservable();
    currentSugoSummaryDate = this.selectedSugoSummaryDate.asObservable();
    currentSugoSummaryUrl = this.selectedSugoSummaryUrl.asObservable();
    changeSelectedSugoSummary(amend: ManualOrderAmend) {
        this.selectedSugoSummaryDCID.next(amend.storeID);
        this.selectedSugoSummaryDate.next(amend.deliveryDate);
        this.selectedSugoSummaryUrl.next(amend.url);
    }

    private selectedIgnoreOnorderLowStWr = new BehaviorSubject<any>(1);
    currentIgnoreOnorderLowStWr = this.selectedIgnoreOnorderLowStWr.asObservable();
    changeSelectedIgnoreOnorderLowStWr(item: any) {
        this.selectedIgnoreOnorderLowStWr.next(item);
    }

    private selectedShowPromoLowStWr = new BehaviorSubject<any>(1);
    currentselectedShowPromoLowStWr = this.selectedShowPromoLowStWr.asObservable();
    changeselectedShowPromoLowStWr(item: any) {
        this.selectedShowPromoLowStWr.next(item);
    }

    private selectedPromoDepType = new BehaviorSubject<any>(1);
    currentPromoDepType = this.selectedPromoDepType.asObservable();
    changeSelectedPromoDepType(item: any) {
        this.selectedPromoDepType.next(item);
    }

    //loadScreenFunctions(userId) {
    //    this._backendConnector.get('/Menu/GetScreenFunction?userID=' + userId).subscribe(
    //        result => {
    //            this.spinner.hide();
    //            debugger;
    //            var response: any = result;
    //            localStorage.setItem('screenFunction', JSON.stringify(response));
    //            resolve('done');
    //            console.log("screenFunction");
    //            console.log(result);

    //            //var sss = JSON.parse(localStorage.getItem('screenFunction'));
    //            //console.log(sss);

    //        }); }

}
