import { CommonService } from "./../services/common-service.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { User } from "../models/administration/user";
import { LoginVm } from "../models/administration/login-vm";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { BackEndApiConnectorService } from "../services/backend-service-connector";
import { finalize } from "rxjs/operators";
import { UserSessionLogOutModel } from "../models/administration/user-session";
import { LogoutType } from "../models/common/finap-enum";
import { ItemDetails } from "../models/item/Item";
import { SharedService } from "../services/shared-service.service";

@Injectable()
export class AuthService {
    private loggedIn = new BehaviorSubject<boolean>(false);
    private _loggedUser = new BehaviorSubject<User>(new User());
    private _backendConnector: BackEndApiConnectorService;
    private _commonService: CommonService;

    constructor(
        private sharedService: SharedService,
        private router: Router,
        private http: HttpClient,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService
    ) {
        this._backendConnector = new BackEndApiConnectorService(
            this.http,
            this.toastr
        );
        this._commonService = new CommonService(this.http, this.toastr);
    }

    public getLoggedUser() {
        return this._loggedUser.asObservable();
    }

    public get isLoggedIn() {
        if (localStorage.getItem("isLogedIn")) {
            this.loggedIn.next(true);
        } else {
            this.loggedIn.next(false);
        }
        return this.loggedIn.asObservable();
    }

    /**
     * @description Logs in user with given LoginVm object.
     * @param loginVM LoginVm object to login with.
     * @returns Promise that resolves with "Login success" string if login is successful, otherwise rejects with "Login failed" string.
     */
    login(loginVM: LoginVm) {

        return new Promise((resolve, reject) => {

            loginVM.applicationClientID = 5;
            if (loginVM.userName !== "" && loginVM.userPassword != "") {
                loginVM.email = loginVM.userName;
                this._backendConnector
                    .post("UserLogin/Login", loginVM)
                    .pipe(finalize(() => { }))
                    .subscribe((result) => {

                        console.log(result);
                        if (result != null) {
                            var user = new User();
                            user = result as User;
                            // this.loadMenue(user.userID, user.userRoleID).then((result) => {
                            //     localStorage.setItem("currentUser", JSON.stringify(user as User));
                            //     localStorage.setItem("isLogedIn", "true");
                            //     debugger;
                            //     console.log("Login success, moving to dashboard");
                            //     this.router.navigate(['pages/dashboard']);
                            //     resolve('Login success');
                            // }) {JMSL.OMS.BackEnd.BusinessObjects.FinApApiError} Data Successfully Saved

                            this.spinner.show();
                            this._backendConnector
                                .get(
                                    "/Menu/GetMainMenu?userID=" +
                                    user.userID +
                                    "&isMobileRequest=false" +
                                    "&userRoleID=" +
                                    user.userRoleID
                                )
                                .pipe(finalize(() => { }))
                                .subscribe((result: any) => {
                                    var response: any = result;
                                    localStorage.setItem(
                                        "mainMenu",
                                        JSON.stringify(response.menu)
                                    );
                                    resolve("done");
                                    console.log("done");
                                    console.log(result);
                                    localStorage.setItem(
                                        "currentUser",
                                        JSON.stringify(user as User)
                                    );
                                    localStorage.setItem("isLogedIn", "true");
                                    console.log("Login success, moving to dashboard");
                                    this.spinner.hide();
                                    this.router.navigate(["pages/dashboard"]);
                                    resolve("Login success");
                                });

                            this.sharedService.screenFunction = [];
                            this._backendConnector
                                .get("/Menu/GetScreenFunction?userID=" + user.userID)
                                .pipe(finalize(() => { }))
                                .subscribe((result) => {
                                    var response: any = result;
                                    this.sharedService.screenFunction = result;
                                    localStorage.setItem(
                                        "screenFunction",
                                        JSON.stringify(response)
                                    );
                                    resolve("done");
                                    console.log("screenFunction");
                                    console.log(result);

                                    //var sss = JSON.parse(localStorage.getItem('screenFunction'));
                                    //console.log(sss);
                                });

                            this._backendConnector
                                .get("Item/GetAllItemDetailsNew?UserID=" + user.userID)
                                .pipe(finalize(() => { }))
                                .subscribe((result: any) => {
                                    //resolve(result);
                                    //console.log("Items Loaded and saved");

                                    localStorage.setItem(
                                        "items",
                                        JSON.stringify(result as ItemDetails[])
                                    );
                                    this.sharedService.changeSelectedDepartmentType(
                                        result[0].departmentType
                                    );
                                });
                            //console.log(user);
                        } else {
                            resolve("Login failed");
                        }
                    });
            }
        });
    }

    logout() {
        var user = this._commonService.getCurrentUser();

        if (user.userID > 0) {
            var session = new UserSessionLogOutModel();
            (session.userID = user.userID),
                (session.userSessionID = user.userSessionID),
                (session.logoutType = LogoutType.Self);
            this._backendConnector
                .post("UserLogin/UserSessionLogout", session)
                .subscribe((result) => {
                    
                    if (result != null) {
                        this._loggedUser.next(null);
                        localStorage.removeItem("currentUser");
                        localStorage.removeItem("items");
                        localStorage.removeItem("isLogedIn");
                        localStorage.removeItem("screenFunction");
                        localStorage.removeItem("mainMenu");
                        this.toastr.success("Successfully log out!");
                        this.router.navigate(["login"]);
                        // window.location.reload();
                    }
                });
        }
    }

    loadMenue(userId: number, userRoleID: string) {
        debugger;
        return new Promise((resolve, reject) => {
            this.spinner.show();
            this._backendConnector
                .get(
                    "/Menu/GetMainMenu?userID=" +
                    userId +
                    "&isMobileRequest=false" +
                    "&userRoleID=" +
                    userRoleID
                )
                .subscribe((result) => {
                    this.spinner.hide();
                    debugger;
                    var response: any = result;
                    localStorage.setItem("mainMenu", JSON.stringify(response.menu));
                    resolve("done");
                    console.log("done");

                    console.log(result);
                });
            this.sharedService.screenFunction = [];
            this._backendConnector
                .get("/Menu/GetScreenFunction?userID=" + userId)
                .subscribe((result) => {
                    this.spinner.hide();
                    debugger;
                    var response: any = result;
                    this.sharedService.screenFunction = result;
                    localStorage.setItem("screenFunction", JSON.stringify(response));
                    resolve("done");
                    console.log("screenFunction");
                    console.log(result);

                    //var sss = JSON.parse(localStorage.getItem('screenFunction'));
                    //console.log(sss);
                });
        });
    }
}
