import "pace";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthGuard } from "./auth/auth.guard";
import { AuthService } from "./auth/auth.service";
import { AgmCoreModule } from "@agm/core";
import { routing } from "./app.routing";
import { AppConfig } from "./app.config";
import { AppComponent } from "./app.component";
import { ErrorComponent } from "./pages/error/error.component";
import { HttpClientModule } from "@angular/common/http";
import { SharedService } from "./services/shared-service.service";
import { HttpModule } from "@angular/http";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { DatePipe } from "@angular/common";
import { ExcelService } from "../app/theme/components/excelexport/excel.service";

@NgModule({
  declarations: [AppComponent, ErrorComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDe_oVpi9eRSN99G4o6TwVjJbFBNr58NxE"
    }),
    routing
  ],
  providers: [
    AppConfig,
    SharedService,
    AuthGuard,
    AuthService,
    DatePipe,
    ExcelService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }