
import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { Router } from '@angular/router';
import { FinApApiresponce, FinApApiError, ErroMessage } from '../models/common/finap.api.reponse';
import { ToastrService } from 'ngx-toastr';
import { User } from '../models/administration/user';
import 'rxjs/add/operator/timeoutWith';


@Injectable()
export class BackEndApiConnectorService implements OnInit {

  weekNumbers: string[];
  private selectedItemSource = new BehaviorSubject<any>([]);
  currentSelectedItem = this.selectedItemSource.asObservable();

  private readonly _baseURL = "http://localhost:17591/";
  //private readonly _baseURL = "https://jmsl-oms-dev-backend.azurewebsites.net/";


  private _httpHeaders: HttpHeaders;
  private httpHeaders2: HttpHeaders;

  constructor(private _httpClient: HttpClient, private toastr: ToastrService) {
    this.weekNumbers = [];

    this._httpHeaders = new HttpHeaders();
    this._httpHeaders.set('TraseID', "");
    this._httpHeaders.set('UserSessionID', "");

    this.httpHeaders2 = new HttpHeaders();
    this.httpHeaders2.set('TraseID', "");
    this.httpHeaders2.set('UserSessionID', "");

    var user = JSON.parse(localStorage.getItem('currentUser'))  as User;
    if (!user) {
      this._httpHeaders.set('UserID', "");
      this._httpHeaders.set('UserSessionID', "");

      this.httpHeaders2.set('UserID', "");
      this.httpHeaders2.set('UserSessionID', "");
    }
    else{
      this._httpHeaders.set('UserID', user.userID.toString());
      this._httpHeaders = this._httpHeaders.append('UserSessionID',user.userSessionID);
      this._httpHeaders = this._httpHeaders.append('TokenID',user.userSessionID);

      this.httpHeaders2.set('UserID', user.userID.toString());
      this.httpHeaders2 = this.httpHeaders2.append('UserSessionID',user.userSessionID);
      this.httpHeaders2 = this.httpHeaders2.append('TokenID',user.userSessionID);
    }
      
    this._httpHeaders.set('Content-Type', 'application/json');//userSessionIDthis._httpHeaders = new HttpHeaders();
    
    this.httpHeaders2.set('Content-Type', 'multipart/form-data');
  }

  ngOnInit() {

  }

  changeSelectedItem(item: any) {
    this.selectedItemSource.next(item);
  }

  

  public get(url: string, showMessage = false,withoutBaseUrl = false): Observable<Object> {
    if(withoutBaseUrl){
      return this._httpClient
      .get(url)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          debugger;
          this.toastr.error(err.message);
          return of();
        })
      );
    }else{
      return this._httpClient
      .get(this._baseURL + url, { headers: this._httpHeaders })
      .pipe(
        map((response: any) => {
          this.readResponse(response, showMessage);
          return response.result;
        }),
        catchError((err) => {
          this.toastr.error(err.message);
          return of();
        })
      );
    }
    
  }

  public post(url: string, data, showMessage = false): Observable<Object> {
    return this._httpClient 
        .post(this._baseURL + url, data, { headers: this._httpHeaders}) 
        .timeoutWith(3000000,"") 
      .pipe(
        map((response: any) => {
          this.readResponse(response, showMessage);
          return response.result;
        }),
        catchError((err) => {
          this.toastr.error(err.message);
          return of();
        })
      );
  }

  public postNew(url: string, data, showMessage = false): Observable<Object> {
    return this._httpClient 
        .post(url, data, { headers: this._httpHeaders}) 
        .timeoutWith(3000000,"") 
      .pipe(
        map((response: any) => {
          this.readResponse(response, showMessage);
          return response.result;
        }),
        catchError((err) => {
          this.toastr.error(err.message);
          return of();
        })
      );
  }



  public postFormData(url: string, data, showMessage = false): Observable<Object> {
    return this._httpClient 
        .post(this._baseURL + url, data, { headers: this.httpHeaders2}) 
      .timeoutWith(300000,"") 
      .pipe(
        map((response: any) => {
          this.readResponse(response, showMessage);
          return response.result;
        }),
        catchError((err) => {
          this.toastr.error(err.message);
          return of();
        })
      );
  }

  public getWeekList(): string[] {
    for (let i = 1; i <= 52; i++) {
      this.weekNumbers.push("" + i);
    }
    return this.weekNumbers;
  }

  private readResponse(response: FinApApiresponce, showMessage: boolean): void {
    if (response == null)
      this.toastr.error("Server Error! Cannot process your request");
    else {
      switch (response.statusCode.toString()) {
        case '200':
        case '201':
        case '202':
        case '203':
        case '204':
          if (showMessage)
            this.toastr.success(this.getMessage(response.errorList), '',{ enableHtml: true });
          break;
        //500
        case '500'://Internal Server Error
        case '501':
        case '502':
        case '503':
        case '504':
        case '505':
        case '506':
        case '507':
        case '508':
        case '509':
        case '510':
          this.toastr.error(this.getMessage(response.errorList), '');//this.toastr.error(response.statusCode + ' - Server Error! Cannot process your request', '');
          break;
        default:
          this.toastr.error(this.getMessage(response.errorList), '',{ enableHtml: true });
          break;
      }
    }
  }

  private getMessage(errorList: FinApApiError[]): string {
    var message: string = 'Message not found';
    if (errorList != null || errorList.length > 0) {
      // message = errorList[0].statusMessage + ' - ' + this.getErrorMessage(errorList[0].statusMessage);
      message = errorList[0].statusMessage + ' - ' + errorList[0].errorMessage;
    }
    return message;
  }

  public getErrorMessage(errorCode: string): string {
    var message = "Message Not Found";
    var errorMessages = JSON.parse(localStorage.getItem('errorMessages'));
    if (errorMessages != null) {
      var errormessage = (errorMessages as ErroMessage[]).find(c => c.errorID == errorCode);
      if (errormessage != null)
        return errormessage.errorDescription;
    }
    return message;
  }

}