import { UserPurcModel } from "../user-purc/user-purc-model";
import { UserRoleModel } from "../User/user-role-model";

export class User {
    loginId: string;
    userID: number;
    userName: string;
    userPassword: string;
    purcID:number;
    purchaseNumber:string;
    userPurcID:number;
    firstName: string;
    fullName: string;
    initials: string;
    lastName: string;
    address: string;
    nic: string;
    passportNo: string;
    email: string;
    isAllowConcurrentConnection: boolean;
    userSessionID: string;
    loginTime: Date;
    logoutTime: Date;
    isActive: boolean;
    remark: string;
    createdUser: number;
    userFunctionTypeID: number;
    userStatus: UserStatus;
    userGroup: UserGroup;
    userPurc: UserPurcModel;
    userRoleID: string;
    userRole: UserRoleModel;
    roleID: number;
}

export class UserStatus {
    userStatusID: number;
    userID: number;
    isCurrent: boolean;
    userStatusType: number;
}

export class UserGroup {
    groupId: number;
    groupName: string;
    isEnable: boolean;
}