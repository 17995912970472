import { ItemDetails } from './../models/item/Item';

import { User } from './../models/administration/user';
import { Injectable } from '@angular/core';
import { BackEndApiConnectorService } from './backend-service-connector';
import { HttpClient } from '../../../node_modules/@angular/common/http';
import { ToastrService } from '../../../node_modules/ngx-toastr';
import { finalize } from '../../../node_modules/rxjs/operators';
import CustomStore from 'devextreme/data/custom_store';
import { Observable } from 'rxjs';

// class Complaints {
//   complaint: string;
//   count: number
// }

// export class ComplaintsWithPercent {
//   ItemDescription: string;
//   DIStockValue: number;
//   ExpiryDayCnt: number;
//   StockHoldingDayCnt: number;
// }

// let complaintsData: Complaints[] = [
//   { complaint: "Cold pizza", count: 780 },
//   { complaint: "Not enough cheese", count: 120 },
//   { complaint: "Underbaked or Overbaked", count: 52 },
//   { complaint: "Delayed delivery", count: 1123 },
//   { complaint: "Damaged pizza", count: 321 },
//   { complaint: "Incorrect billing", count: 89 },
//   { complaint: "Wrong size delivered", count: 222 }
// ];

@Injectable()
export class CommonService {
  _backendSeviceConnector: BackEndApiConnectorService;


  constructor(private httpClient: HttpClient, private toastr: ToastrService) {
    this._backendSeviceConnector = new BackEndApiConnectorService(httpClient, toastr);
  }

  fetchData(url: string, showMessage: boolean = false, withoutBase = false): Promise<any> {
    return new Promise((resolve, error) => {
      this._backendSeviceConnector.get(url, showMessage, withoutBase)
        .pipe(finalize(() => { }))
        .subscribe((result: any) => {
          resolve(result);
        });
    });
  }
  getExcelData(url: string, data: any, showMessage: boolean = false): Promise<any> {
    return new Promise((resolve, error) => {
      this._backendSeviceConnector.get(url, data, showMessage)
        .pipe(finalize(() => { }))
        .subscribe((result: any) => {
          resolve(result);
        });
    });
  }

  postData(url: string, data: any, showMessage: boolean = false): Promise<any> {
    return new Promise((resolve, error) => {
      this._backendSeviceConnector.post(url, data, showMessage)
        .pipe(finalize(() => { }))
        .subscribe((result: any) => {
          resolve(result);
        });
    });
  }

    postDataNew(url:string,data:any,showMessage:boolean = false): Promise<any> {
      return new Promise((resolve, error) => {
          this._backendSeviceConnector.postNew(url,data,showMessage)
              .pipe(finalize(() => { }))
              .subscribe((result: any) => {
                  resolve(result);
              });
      });
  }

    postFormData(url:string,data:any,showMessage:boolean = false): Promise<any> {
      return new Promise((resolve, error) => {
          this._backendSeviceConnector.postFormData(url,data,showMessage)
              .pipe(finalize(() => { }))
              .subscribe((result: any) => {
                  resolve(result);
              });
      });
  }

  public getUserIP() {
    /*  return new Promise((resolve, reject) => {
         this._backendSeviceConnector.get('https://jsonip.com',true,true)
         .subscribe(data => {
           resolve(data);
         })
     }); */

    return "203.115.25.68";
  }

  public GetItems() {
    if (!localStorage.getItem('items')) {
      this.toastr.error('No Items found');
      var i = new ItemDetails();
      return i;
    } else {
      var items = JSON.parse(localStorage.getItem('items'));
      return items as ItemDetails[];
    }
  }

  GetItemsDataSource() {
    var self = this;
    return new CustomStore({
      loadMode: "raw",
      key: "itemID",
      load: function () {
        return new Promise((resolve, error) => {
          resolve(self.GetItems())
        });
      }
    });
  };

  GetMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  }

  //This will return Monday as the week start date
  GetWeekStartDate() {
    var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    var firstday = new Date(curr.setDate(first));
    return firstday;
  }


  //This will return Sunday as the week end date
  GetWeekEndDate() {
    var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6
    var lastday = new Date(curr.setDate(last));
    return lastday;
  }

  makeAsyncDataSource(url: string, key: string,
    connector: BackEndApiConnectorService = this._backendSeviceConnector) {
    return new CustomStore({
      loadMode: "raw",
      key: key,
      load: function () {
        return new Promise((resolve, error) => {
          connector.get(url)
            .pipe(finalize(() => { }))
            .subscribe((result: any) => {
              resolve(result);
            });
        });
      }
    });
  };

  makeAsyncDataSourceFromList(url: string, key: string,slectedList: string,connector:BackEndApiConnectorService = this._backendSeviceConnector) {
    return new CustomStore({
      loadMode: "raw",
      key: key,
      load: 
      function () {
        return new Promise((resolve, error) => {
            connector.get(url)
            .pipe(finalize(() => { }))
            .subscribe((result: any) => {
              resolve(result[slectedList]);               
            });
        });
      }
    });
  };

  makeAsyncDataSourceWithoutBase(url: string, key: string,
    connector: BackEndApiConnectorService = this._backendSeviceConnector) {

    return new CustomStore({
      loadMode: "raw",
      key: key,
      load: function () {
        return new Promise((resolve, error) => {
          connector.get(url, false, true)
            .pipe(finalize(() => { }))
            .subscribe((result: any) => {
              resolve(result);

            });
        });
      }
    });
  };


  public getCurrentUser(): User {
    if (!localStorage.getItem('currentUser')) {
      this.toastr.error('User Not found');
      console.log('no user details in the session');
      var u = new User();
      return u;
    }
    else
      var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser == null) {
      this.toastr.error('User Not found');
      var u = new User();
      return u;
    }
    else {
      var user = currentUser as User;
      return user;
    }
  }

  makeAsyncDataSourceDepartment(url: string, key: string,
    connector: BackEndApiConnectorService = this._backendSeviceConnector) {
    return new CustomStore({
      loadMode: "raw",
      key: key,
      load: function () {
        return new Promise((resolve, error) => {
          connector.get(url)
            .pipe(finalize(() => { }))
            .subscribe((result: any) => {
              resolve(result);

            });
        });
      }
    });
  };

  makeAsyncDataSourceForRoute(url: string, key: string, supplierId: any,
    connector: BackEndApiConnectorService = this._backendSeviceConnector) {
    {
      return new CustomStore({
        loadMode: "raw",
        key: key,
        load: function () {
          return new Promise((resolve, error) => {
            connector.get(url)
              .pipe(finalize(() => { }))
              .subscribe((result: any) => {
                let filtRes = result.filter(x => x.supplierId == supplierId)
                resolve(filtRes);
              });
          });
        }
      });
    };
  }

  getComplaintsData(tableChartData : any) {
    debugger;
    console.log("tableChartData in service");
    console.log(tableChartData);
    // var data = complaintsData.sort(function (a, b) {
    //         return b.count - a.count;
    //     }),
    //     totalCount = data.reduce(function (prevValue, item) {
    //         return prevValue + item.count;
    //     }, 0),
    //     cumulativeCount = 0;
    return tableChartData.map(function (item, index) {
        return {
          ItemDescription: item.itemDescription,
          DIStockValue: item.diStockValue,
          ExpiryDayCnt: item.expiryDayCnt,
          StockHoldingDayCnt: item.stockHoldingDayCnt
        };
    });
  }

  getGapsScanData(tableChartData : any) {
    debugger;
    console.log("getGapsScanData in service");
    console.log(tableChartData);
    
    return tableChartData.map(function (item, index) {
        return {
          DepartmentName: item.departmentName,
          IsThisWeek: item.isThisWeek,
          AvailabilityCount: item.availabilityCount,
          Gapscount: item.gapscount
        };
    });
  }

}