
import { ItemSplitHistory } from '../DailySplitReview/ItemSplitHistory';



export class ItemDetails
    {
        itemID: number;
        purcID: number;
        orderGroupID: number;
        itemNumber: string;
        departmentID: number;
        subDepartmentID: number;
        categoryID: number;
        subCategoryID: number;
        merchandiseCategoryID: number;
        baseUOM: number;
        storeOrderUOM: number;
        supplierUOM: number;
        baseToSupplierUOMConversion: number;
        baseToStoreUOMConversion: number;
        supplierID: number;
        purchaseNumber: string;
        orderGroupName: string;
        departmentName: string;
        subDepartmentName: string;
        categoryName: string;
        itemDescription: string;
        itemLevel:number;
        uomNumber: string;
        percentage: number;
        rank: number;
        supplierName: string;
        minQuantity: number;
        maxQuantity: number;
        subCategoryName: string;
        merchandiseCategoryName: string;
        unitsUOM:number;
        storeID:number;
        hierachyLevel:string;
        prevSplitLevel:any;
        effectiveDate:any;
        itemSplitHistory:ItemSplitHistory[];
        storeTypeId:number;

    }

    export class ItemHeirarchyBreakdown {
        id: number;
        head_ID: number;
        title: string;
        hierarchy_Level_ID: number;
        item_ID: number;
        no_Of_History_Weeks:number;
        no_Of_Forecast_Weeks:number;
        level: string;
        default_Parameter: string;
        departmentID: number;
        subDepartmentID: number;
        categoryID: number;
        subCategoryID: number;
        merchandiseCategoryID: number;
        forecastParameterID:number;
        defaultHeirarchy:number;
        defaultHeirarchyDesc:string;
        activeProductOnLevel:number;
      }